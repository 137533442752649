<template>
  <div>
    <el-row justify="space-between">
      <el-col
        :sm="24"
        :md="12"
        :lg="12"
        v-if="checkUsrPermissions('Referral Disbursement Store')"
      >
        <el-button type="primary" @click="$router.push('/disbursements/create')"
          >Create new Disbursement</el-button
        >
      </el-col>
    </el-row>

    <el-row :gutter="20" align="middle">
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Start date</p>
        <el-date-picker
          v-model="filter.start_date"
          type="date"
          class="w-100"
          placeholder="Pick a day"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">End date</p>
        <el-date-picker
          v-model="filter.end_date"
          type="date"
          class="w-100"
          placeholder="Pick a day"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Status</p>
        <el-select v-model="filter.status" class="m-2 w-100" size="large" clearable>
          <el-option value="" label="Select One"/>
          <el-option value="1" label="Disbursed"/>
          <el-option value="0" label="Pending"/>
        </el-select>
      </el-col>
      <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getItems" round
          >Filter
        </el-button>
        <el-button type="danger" size="large" @click="clear" round
          >Clear
        </el-button>
      </el-col>
    </el-row>

    <br />

    <el-table :data="items" border v-loading="loading">
      <el-table-column
        prop="uid"
        label="UID"
        align="center"
        width="170"
      ></el-table-column>
      <el-table-column
        prop="fullname"
        label="Full Name"
        align="center"
        width="175"
      ></el-table-column>
      <el-table-column
        prop="user_type"
        label="User Type"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        prop="phone_number"
        label="Phone Number"
        align="center"
        width="165"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="Amount"
        align="center"
        width="110"
      ></el-table-column>
      <el-table-column prop="status" label="Status" align="center" width="90">
        <template #default="scope">
          <el-tag class="ml-2" type="warning" v-if="scope.row.status == 0"
            >Pending</el-tag
          >
          <el-tag class="ml-2" type="success" v-if="checkUsrPermissions('Referral Disbursement Details') && scope.row.status == 1"
            >Disbursed</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="disbursed_at"
        label="Disbursed At"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="payment_method"
        label="Payment Method"
        align="center"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="created_at"
        label="Created At"
        align="center"
        width="175"
      ></el-table-column>
      <el-table-column prop="actions" label="Actions" width="110">
        <template #default="scope">
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-button type="info" circle>
                <el-icon><More /></el-icon>
              </el-button>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="showStatusDialog(scope.row)"
                  v-if="
                    checkUsrPermissions('Referral Disbursement Status Update') &&
                    scope.row.status == 0
                  "
                >
                  Mark as Disbursed
                </el-dropdown-item>
                <el-dropdown-item  v-if="checkUsrPermissions('Referral Disbursement Details')">
                  <router-link :to="`/referrals/disbursements/${scope.row.uid}`" class="router_link">
                    Disbursement Details
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      :page-size="per_page"
      layout="total, prev, pager, next"
      :total="total_count"
      @current-change="getItems"
    />

    <el-dialog
      v-model="statusDialog"
      title="Disbursement Status Update"
      class="appointment-status-modal"
    >
      <el-form
        :model="form"
        label-position="top"
        label-width="100px"
        ref="form"
      >
        <el-form-item label="Disbursement Proof (attachment)">
          <input type="file" ref="attachment" id="attachment" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="statusDialog = false">Cancel</el-button>
          <el-button @click="statusAction('form')"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { CenterMixins } from "@/mixins/CenterMixins";
import { mapGetters } from "vuex";
export default {
  name: "Disbursement",
  title: "Disbursements",
  mixins: [CenterMixins],
  data() {
    return {
      items: [],
      total_count: 0,
      currentPage: 0,
      per_page: 20,
      filter: {
        start_date: null,
        end_date: null,
        status: "",
      },
      statusDialog: false,
      selectedItem: {},
    };
  },
  created() {
    this.getItems();
  },
  computed: {
    ...mapGetters("auth", ["userInstitute", "authUser"]),
  },
  methods: {
    getItems(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }

      const filter = `?limit=${this.per_page}&offset=${correctValue || 0}
            ${
              this.filter.start_date
                ? "&start_date=" + this.filter.start_date
                : ""
            }
            ${this.filter.end_date ? "&end_date=" + this.filter.end_date : ""}
            ${this.filter.status ? "&status=" + this.filter.status : ""}`;

      axios.get(`api/v2/referrals/disbursements${filter}`).then((res) => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
      });
    },
    showStatusDialog(item) {
      this.selectedItem = item;
      this.statusDialog = true;
    },
    statusAction() {
      const attachment = this.$refs.attachment.files
        ? this.$refs.attachment.files[0]
        : "";
      let form = new FormData();
      if (attachment) {
        form.append("attachment", attachment);
      }
      axios
        .post(
          `/api/v2/referrals/disbursements/${this.selectedItem.id}/status`,
          form
        )
        .then((res) => {
          if (res.data.status_code != 200) {
            this.$notify({
              title: "Failed",
              message: res.data.message,
              type: "error",
              duration: 2000,
            });
          } else {
            this.statusDialog = false;
            this.$notify({
              title: "Success",
              message: "Request Successfully Disbursed",
              type: "success",
              duration: 2500,
            });
            this.getItems();
          }
        });
    },

    clear() {
      this.filter = {};
      this.getItems();
    },
  },
};
</script>
<style>
.w-100 {
  width: 100% !important;
}

.router_link{
  color: var(--el-text-color-regular);
}
</style>
